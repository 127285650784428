import { useLocation, useSearchParams } from 'react-router-dom';
import HomePage from '../pages/page/index';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Config, Data } from '@measured/puck';
import {
  checkoutService,
  LoadingPage,
  showNotification,
  StatusCode,
} from 'shared-components';
import checkoutConfig from 'puck/checkout-puck-config';
import { ConvertBase64ToJson } from 'shared-components';
import _ from 'lodash';
import { StartCheckoutPayload } from 'shared-components/src/page-builder/interface/apiServiceInterface';
import ErrorMessage from 'components/error-message';
import { IErrorResponse } from 'shared-components/src/page-builder/interface/apiInterface';
import { setCartData } from 'shared-components/src/page-builder/redux/slices/cartSlice';
import { setStoreData } from 'shared-components/src/page-builder/redux/slices/storeSlice';
import { useAppSelector } from 'hooks/reduxHooks';
import { useDispatch } from 'react-redux';

const Home = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const storeData = useAppSelector(state => state.store.storeData);
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get('store')?.split('.')?.[0];
  const cartId = searchParams.get('cart');
  const trafficSource = searchParams.get('trafficSource');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [puckConfig, setPuckConfig] = useState<Config | null>();
  const [puckData, setPuckData] = useState<Data>();

  const handlePreloadError = (error: AxiosError) => {
    if (error?.response?.status === 400 || error?.response?.status === 404) {
      setError(
        'There was a problem with your request. Please check your information and try again.',
      );
    } else {
      setError(
        'Something went wrong! Try refreshing the page or try again later!',
      );
    }
  };

  const getPageDeatils = async () => {
    try {
      if (!shopId || !cartId) {
        showNotification(
          StatusCode.failed,
          `Invalid ${shopId ? '' : 'store Id'}  ${cartId ? '' : 'cart Id'}`,
        );
        return;
      }

      setIsLoading(true);
      const payload: StartCheckoutPayload = {
        CartID: cartId,
        ShopID: shopId,
        TrafficSource: trafficSource || '',
      };
      const res = await checkoutService.getCheckout(payload);
      if (res?.status === 200 && res?.data) {
        const configData = ConvertBase64ToJson(res?.data?.PageData?.PageData);
        if (!_.isEmpty(configData)) {
          setPuckData(configData);
          let puckConfig;
          if (
            ['upsell', 'checkout', 'confirmation', 'other'].includes(
              res?.data?.PageData?.PageType,
            )
          ) {
            puckConfig = checkoutConfig() as Config;
          } else {
            setError(
              'There was a problem with your request. Please check your information and try again.',
            );
          }
          dispatch(setCartData(res.data?.Cart));
          dispatch(
            setStoreData({
              ...storeData,
              StoreID: res.data.Cart?.StoreID,
              ShopID: shopId,
            }),
          );
          setPuckConfig(puckConfig);
        }
      }
    } catch (error) {
      const errorResponse = error as IErrorResponse<AxiosError>;
      handlePreloadError(errorResponse.error as AxiosError);
      console.log('Error in getting page details', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPageDeatils();
  }, [pathname, searchParams]);

  return (
    <>
      {isLoading && <LoadingPage />}
      {error ? (
        <ErrorMessage errorMessage={error} />
      ) : (
        puckConfig &&
        puckData && (
          <HomePage config={puckConfig} data={puckData} pathname={pathname} />
        )
      )}
    </>
  );
};

export default Home;
