import React from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { useNavigate, NavigateFunction } from 'react-router-dom';

interface ErrorMessageProps {
  errorMessage: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage }) => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <>
      {errorMessage && (
        <div className="flex items-center justify-center h-auto">
          <div className="sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4 mt-20">
            <div className="rounded-md bg-red-50 p-4 mt-12 flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  {errorMessage}
                </h3>
                <br />
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="bg-transparent border !border-red-500 hover:bg-red-200 text-blue-500 hover:text-white font-semibold text-base py-2 px-4 rounded inline-block">
                  <h3 className="text-red-800">Dismiss</h3>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorMessage;
