import Home from 'components/page-builder/home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PageNotFound } from 'shared-components';
import { Path } from 'shared-components';
import { PuckWrapper } from './PuckWrapper';

export const CheckoutRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PuckWrapper />}>
          <Route path={Path.CHECKOUT} element={<Home />} />
          <Route path="/*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
