import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks';
import { ComponentConfig, type Config } from '@measured/puck';
import {
  ColumnsProps,
  FlexProps,
  PuckRenderPage,
  ContainerProps,
  VerticalSpaceProps,
  SimplePageHeaderProps,
  BillingFormProps,
  TextFieldProps,
  ContactInfoProps,
  fontFamilies,
  TimerProps,
  UpSellTimerProps,
  ShippingMethodSelectProps,
  PaymentProps,
  SingleStepButtonSectionProps,
  TermsAndSubProps,
  AccordionProps,
  UpSellProps,
  CustomerReviewsProps,
  OrderDetailsProps,
  DividerProps,
  MarginProps,
  HeaderProps,
  TextEditorProps,
  ButtonSectionProps,
  ImageUploaderProps,
  CustomerGuarnteesProps,
  VipComponentProps,
  UpsellDealProps,
  StandardLayoutProps,
  SplitLayoutProps,
  PromoCodeProps,
  AlwaysVipProps,
  SavingCalculatorProps,
  ProductProps,
  NextButtonProps,
  PaymentRadioProps,
  createComponentConfigs,
} from 'shared-components';

type Props = {
  // Layouts
  SplitLayout: SplitLayoutProps;
  StandardLayout: StandardLayoutProps;
  // Blocks
  Columns: ColumnsProps;
  Flex: FlexProps;
  VerticalSpace: VerticalSpaceProps;
  Divider: DividerProps;
  Container: ContainerProps;
  // Text
  TextField: TextFieldProps;
  TextEditor: TextEditorProps;
  // Buttons
  Button: ButtonSectionProps;
  ConfirmPurchase: SingleStepButtonSectionProps;
  // Page Headers
  SimplePageHeader: SimplePageHeaderProps;
  // Form Components
  ContactInfo: ContactInfoProps;
  ShippingForm: MarginProps;
  BillingForm: BillingFormProps;
  ShippingMethodSelect: ShippingMethodSelectProps;
  Payment: PaymentProps;
  // Checkout Components
  Cart: MarginProps;
  Timer: TimerProps;
  TimerUpSell: UpSellTimerProps;
  TermsAndSub: TermsAndSubProps;
  Accordion: AccordionProps;
  UpSell: UpSellProps;
  CustomerReviews: CustomerReviewsProps;
  OrderDetails: OrderDetailsProps;
  CustomerGuarantees: CustomerGuarnteesProps;
  ImageUploader: ImageUploaderProps;
  PromoCode: PromoCodeProps;
  VipComponent: VipComponentProps;
  AlwaysVip: AlwaysVipProps;
  SavingCalculator: SavingCalculatorProps;
  PaymentRadio: PaymentRadioProps;
  // upsell
  Header: HeaderProps;
  Product: ProductProps;
  UpsellDeal: UpsellDealProps;
  NextButton: NextButtonProps;
};

export const checkoutConfig = (): Config<Props> => {
  const componentList = createComponentConfigs({
    useAppDispatch,
    useAppSelector,
    type: 'all',
  });
  return {
    components: componentList as {
      [ComponentName in keyof Props]: Omit<
        ComponentConfig<Props[ComponentName], Props[ComponentName]>,
        'type'
      >;
    },
    root: {
      defaultProps: {
        fontFamily: fontFamilies[0].value,
        themeColor: { r: 79, g: 70, b: 229 },
        errorColor: '#FF5A50',
        customCss: [],
        customScripts: [],
      },
      render: ({
        children,
        fontFamily,
        errorColor,
        customCss,
        customScripts,
      }) => (
        <PuckRenderPage
          fontFamily={fontFamily}
          errorColor={errorColor}
          customCss={customCss}
          customScripts={customScripts}
          useAppSelector={useAppSelector}>
          {children}
        </PuckRenderPage>
      ),
    },
  };
};

export default checkoutConfig;
