import { Render } from 'shared-components';
import { Config, Data } from '@measured/puck';
import { Path } from 'shared-components';

interface IProps {
  data: Data;
  config: Config;
  pathname: string;
}

const HomePage = ({ data, config, pathname }: IProps) => {
  return (
    <>
      {pathname.includes(Path.CHECKOUT) && (
        <Render config={config} data={data} />
      )}
    </>
  );
};

export default HomePage;
