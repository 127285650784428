import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';
import { thunk } from 'redux-thunk';

// puck slices
import { cartSlice } from 'shared-components';
import { storeSlice } from 'shared-components';
import { commonSlice } from 'shared-components';
import { checkoutSlice } from 'shared-components';
import { preUpsellSlice } from 'shared-components';
import { termsAndSubSlice } from 'shared-components';
import { postUpsellSlice } from 'shared-components';
import { postUpsellStateSlice } from 'shared-components';

const rootReducer = combineReducers({
  // puck slices
  checkout: checkoutSlice,
  cart: cartSlice,
  store: storeSlice,
  common: commonSlice,
  preUpSell: preUpsellSlice,
  postUpSell: postUpsellSlice,
  postUpSellState: postUpsellStateSlice,
  termsAndSub: termsAndSubSlice,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['shopify'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
